import TokenStore from 'lib/token-store';
import { NextComponentType, NextPageContext } from 'next';
import React, { Component } from 'react';
import { setup } from 'lib/adapter';

interface IPageContext extends NextPageContext {
  reduxStore: any;
  req: any;
}

interface IProps {
  isDraft: boolean;
  oldHomePageId: number;
  userAgent: string;
  visitedUrl: string;
  referer: string;
  hubId: string;
}

const withVisitsLogging = (App: NextComponentType) =>
  class WithVisitsLogging extends Component<IProps> {
    static async getInitialProps(ctx: IPageContext) {
      if (typeof window !== 'object') {
        const host = `https://${ctx.req.headers.host}`;
        const token = ctx.req.cookies['unexpected_thunder'];

        await setup({
          host,
          token,
          req: ctx.req
        });
      }

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

    return {
      ...appProps,
      isDraft: ctx.query.draft,
      oldHomePageId: ctx.reduxStore.getState().homePageRevision.revision.oldHomePageId,
      hubId: ctx.reduxStore.getState().hub.hub.id,
      userAgent: ctx.req?.headers['user-agent'] || '',
      visitedUrl: ctx.req?.url || '',
      referer: ctx.req?.headers?.referer || '',
    };
  }

    fetchCurrentSessionInformation = async () => {
      const host = `${window.location.protocol}//${window.location.host}`;
      const res = await fetch(`${host}/session_info`);
      const data = await res.json();

      const { session_id, user_id, user_type } = data.data.attributes;

      return {
        session_id,
        user_id,
        user_type,
      };
    };

    getPageId = () => {
      switch (this.mapUrlToItemType()) {
        case 'homepage':
          return this.props.oldHomePageId;
        case 'hubpage':
          return this.props.hubId;
        default:
          break;
      }
    };

    mapUrlToItemType = () => {
      const visitedString = '/admin/sites/editors/home-page/live-preview';
      const hubPageString = '/admin/sites/editors/hub-page/';

      if (this.props.visitedUrl.startsWith(visitedString)) {
        return 'homepage';
      }
      if (this.props.visitedUrl.includes(hubPageString)) {
        return 'hubpage';
      }
    };

    getVisitedUrl = () => {
      const host = `${window.location.protocol}//${window.location.host}`;
      const visitedString = '/admin/sites/editors/home-page/live-preview';

      if (this.props.visitedUrl.startsWith(visitedString)) {
        const formatted_path = this.props.visitedUrl.replace(visitedString, '');
        return host + formatted_path;
      }
      return this.props.visitedUrl;
    };

    logVisit = async (
      session_id: number,
      user_id: number,
      user_type: string,
    ) => {
      const { userAgent, referer } = this.props;

      const body = {
        data: {
          attributes: {
            page_id: this.getPageId(),
            item_type: this.mapUrlToItemType(),
            session_id,
            user_id,
            user_type,
            visited_url: this.getVisitedUrl(),
            user_agent: userAgent,
          },
        },
        referrer: referer,
      };

      const host = `${window.location.protocol}//${window.location.host}`;

      const tokenResponse = await fetch(`${host}/api/v2/token`, {
        credentials: 'same-origin',
      });

      const data = await tokenResponse.json();
      const token = data.data.attributes.token;

      await fetch(`${host}/api/v2/logger`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    };

    async componentDidMount() {
      const { isDraft } = this.props;

      // We don't need to log visits for draft revision of any page that supports it (eg: home-page)
      if (!isDraft) {
        const { session_id, user_id, user_type } =
          await this.fetchCurrentSessionInformation();
        this.logVisit(session_id, user_id, user_type);
      }
    }

    render() {
      return <App {...this.props} />;
    }
  };

export default withVisitsLogging;
