import Query from '../lib/prepare-query'
import constants from 'constants/home-page-revisions'
import HomePageRevision from 'api/home-page-revision'

export const fetchLastHomePageRevisions = () => async (dispatch) => {
  const queries = {
    page: 1,
    per_page: 1
  }
  const filters = {
    sort: {
      id: 'desc'
    }
  }
  const query = new Query(queries, filters)

  dispatch({
    type: constants.FETCH_LAST_HOME_PAGE_REVISIONS_REQUEST
  })

  try {
    const revisions = await HomePageRevision.query(query)

    dispatch({
      type: constants.FETCH_LAST_HOME_PAGE_REVISIONS_SUCCESS,
      revision: revisions[0]
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_LAST_HOME_PAGE_REVISIONS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const fetchLastPublishedHomePageRevisions = () => async (dispatch, getState) => {
  const queries = {
    page: 1,
    per_page: 1
  }
  const filters = {
    sort: {
      id: 'desc'
    },
    published: true
  }
  const query = new Query(queries, filters)

  dispatch({
    type: constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_REQUEST
  })

  try {
    const revisions = await HomePageRevision.query(query)

    dispatch({
      type: constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_SUCCESS,
      revision: revisions[0]
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}
